.SplitPane {
    overflow: initial !important;
}
.Resizer {
    background: transparent;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;

    -webkit-transition: background 0.5s ease;
    transition: background 0.5s ease;
}
.Resizer:hover,
.Resizer:active {
    background-color: #a6aaaf;
}
.dark-theme .Resizer:hover,
.dark-theme .Resizer:active {
    background-color: #6c747b;
}

.Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.2);
    border-bottom: 5px solid rgba(0, 0, 0, 0.2);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -6px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.disabled {
    cursor: not-allowed;
}
.Resizer.disabled:hover {
    border-color: transparent;
}
